<template>
  <MatchMedia query="(max-width: 840px)" v-slot="{ matches }">
    <div v-if="matches" class="mobileBox">
      <div class="filter">
        <div class="total">
          <div class="tip">
            Total Members
            <span class="num">
              {{ total }}
            </span>
          </div>
        </div>

        <Tooltip
          v-if="role === 'exhibitor'"
          content="Product Assignment"
          placement="top"
        >
          <div
            @click="$router.push('/member/all/assign')"
            class="item"
            style="cursor:pointer;border:1px solid #f57c00;padding:4px;border-radius:6px;margin-right:10px"
          >
            <Icon type="ios-checkbox" style="font-size:20px;color:#f57c00" />
          </div>
        </Tooltip>

        <div class="item" v-if="is_administrator">
          <div class="tip"></div>
          <router-link to="/invite">
            <Button type="primary" icon="md-add">Invite Member</Button>
          </router-link>
        </div>
      </div>
      <div class="filter">
        <div class="search-box" style="margin:0">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a member..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>
      </div>
      <ul class="lists" v-if="list.length">
        <li v-for="(item, index) in list" :key="index">
          <h3>
            <span>{{ item.first_name }} {{ item.last_name }}</span>
            <span class="fr">
              <i-switch
                :disabled="is_administrator && user_id === item.id"
                :value="item.state === 1 ? true : false"
                size="small"
                @on-change="changeStatus(item)"
              ></i-switch>
            </span>
          </h3>
          <div class="content">
            <div class="con">
              <p class="tit">ROLE</p>
              <p class="name">
                {{ item.is_administrator ? "Administrator" : "Member" }}
              </p>
            </div>
            <div class="con">
              <p class="tit">TELEPHONE</p>
              <p class="name">{{ item.phone }}</p>
            </div>
          </div>
          <div class="footer">
            <div class="doit" @click="edit(item)">
              <Icon type="md-create" />
              Edit Details
            </div>
            <div
              class="doit"
              v-if="is_administrator && user_id !== item.id"
              @click="removeMember(item)"
            >
              <Icon type="ios-remove-circle" />
              Remove Member
            </div>
            <div class="doit not" v-else>
              <Icon type="ios-remove-circle" />
              Remove Member
            </div>
          </div>
        </li>
      </ul>
      <p style="padding:100px 0;text-align:center" v-else>No Data</p>
      <p class="loadingLists" v-if="loading"><spin></spin></p>
      <div class="page">
        <Page
          :page-size="limit"
          :total="total"
          show-total
          @on-change="pageChange"
        />
      </div>
    </div>
    <div class="box" v-else>
      <div class="filter">
        <div class="total">
          <div class="tip">
            Total Members
          </div>
          <div class="num">
            {{ total }}
          </div>
        </div>
        <div class="search-box">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a member..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>

        <Tooltip
          v-if="role === 'exhibitor'"
          content="Product Assignment"
          placement="top"
        >
          <div
            @click="$router.push('/member/all/assign')"
            class="item"
            style="cursor:pointer;border:1px solid #f57c00;padding:4px;border-radius:6px;margin-right:10px"
          >
            <Icon type="ios-checkbox" style="font-size:20px;color:#f57c00" />
          </div>
        </Tooltip>

        <div class="item" v-if="is_administrator">
          <div class="tip"></div>
          <router-link to="/invite">
            <Button type="primary" icon="md-add">Invite Member</Button>
          </router-link>
        </div>
      </div>

      <div style="overflow-y:auto">
        <Table
          :loading="loading"
          :columns="is_administrator ? columnsAction : columns"
          :data="list"
          style="min-width:600px;"
        >
          <template slot-scope="{ row: item }" slot="name">
            <span>{{ item.first_name }} {{ item.last_name }}</span>
          </template>

          <template slot-scope="{ row: item }" slot="status">
            <i-switch
              :disabled="is_administrator && user_id === item.id"
              :value="item.state === 1 ? true : false"
              size="small"
              @on-change="changeStatus(item)"
            ></i-switch>
            &nbsp;&nbsp;
            <span
              v-if="item.state === 1"
              style="font-size:12px;color:#ff6600;vertical-align: bottom;"
              >Active</span
            >
            <span
              v-else
              style="font-size:12px;color:#888;vertical-align: bottom;"
              >Disabled</span
            >
          </template>

          <template slot-scope="{ row: item }" slot="action">
            <Button
              style="color:#ff6600;border:1px solid #ff6600;"
              size="small"
              @click="edit(item)"
              shape="circle"
              icon="md-create"
            ></Button>
            <span>
              <!-- <Divider type="vertical" /> -->
              &nbsp;&nbsp;
              <Button
                v-if="is_administrator && user_id !== item.id"
                style="color:#ff5050;border:1px solid #ff5050;"
                size="small"
                @click="removeMember(item)"
                shape="circle"
                icon="md-remove"
              ></Button>
              <Button
                v-else
                :disabled="true"
                size="small"
                shape="circle"
                icon="md-remove"
              ></Button>
            </span>
          </template>
        </Table>

        <div class="page" style="min-width:600px;">
          <Page
            :page-size="limit"
            :page-size-opts="[5, 10, 15, 20]"
            :total="total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
import { MatchMedia } from "vue-component-media-queries";
const { _queryMemberList, _removeMember, _changeActive } = api;

export default {
  name: "documents",

  computed: {
    ...mapState("user", ["user_id", "is_administrator", "role"])
  },
  components: {
    MatchMedia
  },
  created() {
    this.queryMemberList();
  },

  data() {
    return {
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      filter: {
        search: ""
      },

      columnsAction: [
        {
          title: "NAME",
          key: "name",
          slot: "name"
        },
        {
          title: "TELEPHONE",
          key: "phone"
        },
        {
          title: "STATUS",
          slot: "status"
        },
        // {
        //   title: "ROLE",
        //   key: "is_administrator",
        //   render: (h, params) => {
        //     var role = "";
        //     if (params.row.is_administrator) role = "Administrator";
        //     else role = "Member";
        //     return h("div", role);
        //   }
        // },
        {
          title: "Action",
          key: "action",
          width: 200,
          align: "center",
          slot: "action"
        }
      ],
      columns: [
        {
          title: "NAME",
          key: "name",
          slot: "name"
        },
        {
          title: "EMAIL",
          key: "email"
        },
        {
          title: "ROLE",
          key: "is_administrator",
          render: (h, params) => {
            var role = "";
            if (params.row.is_administrator) role = "Administrator";
            else role = "Member";
            return h("div", role);
          }
        }
      ]
    };
  },

  methods: {
    edit(item) {
      this.$router.push(`/member/${item.id}/edit`);
    },

    handleSearch() {
      this.page = 1;
      this.queryMemberList();
    },

    queryMemberList() {
      this.loading = true;

      _queryMemberList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryMemberList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMemberList();
    },

    changeStatus(item) {
      var willState = item.state === 1 ? 0 : 1;

      _changeActive({
        id: item.id,
        state: willState
      })
        .then(() => {
          this.list.forEach((ite, index) => {
            if (ite.id === item.id)
              this.$set(this.list[index], "state", willState);
          });
        })
        .catch(error => {
          console.log(error);
        });
    },

    removeMember(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete ",
        content: "Are you sure you want to delete this relationship?",
        onOk: () => {
          _removeMember({
            id: item.id
          })
            .then(() => {
              this.$Message.success("success");
              this.queryMemberList(); // refresh
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.$Modal.remove();
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.filter {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  .total {
    color: #333;
    .tip {
    }
    .num {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .search-box {
    flex: 1;
    height: 40px;
    display: flex;
    margin: 0 20px;
    .input-box {
      position: relative;
      flex: 1;
      height: 100%;
      background: #fff;
      line-height: initial;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #dfdfdf;
      border-right: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: none;
        color: #444;
        caret-color: #888;

        &::-webkit-input-placeholder {
          color: #bdcada;
        }
        &:-moz-placeholder {
          color: #bdcada;
        }
        &::-moz-placeholder {
          color: #bdcada;
        }
        &:-ms-input-placeholder {
          color: #bdcada;
        }
      }
    }
    .btn {
      cursor: pointer;
      background: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-self: center;
      line-height: initial;
      height: 100%;
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less" scoped>
.mobileBox {
  .loadingLists {
    width: 22px;
    margin: 100px auto;
  }
  .lists {
    list-style: none;
    li {
      margin-bottom: 10px;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      box-shadow: 0 0 4px #eee;
      h3 {
        font-size: 14px;
        padding: 6px 10px;
        border-bottom: 1px solid #f2f2f2;
        .fr {
          float: right;
        }
      }
      .content {
        display: flex;
        padding: 10px 10px;
        .con {
          flex: 0 0 50%;
          font-size: 12px;
          .tit {
            color: #888;
            padding: 4px 0;
          }
        }
      }
      .footer {
        display: flex;
        font-size: 12px;
        border-top: 1px solid #f2f2f2;
        .doit {
          flex: 0 0 50%;
          text-align: center;
          padding: 6px 0;
          &:first-child {
            color: #ff6600;
          }
          &:last-child {
            color: #ca1f01;
            border-left: 1px solid #f2f2f2;
          }
        }
        .not {
          color: #ccc !important;
        }
      }
    }
  }
}
</style>
